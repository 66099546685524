import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SEO title="About" />
    <div className="mt-16 mb-12">
      <h1 className="mb-12">404: Page Not Found</h1>
      <p className="mb-4">I bet the page would have been great, though.</p>

      <p>I clearly did something wrong when building this, so if you feel inclined, <a href="mailto:mikejhartman@gmail.com">please let me know</a>.</p>

    </div>


  </Layout>
)

export default NotFoundPage
